import React from "react"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import "./Banner.scss"
import BannerPhoto from "../../../assets/images/gre-prep-banner.png"

interface BannerProps {
    onMissionClick: () => void;
}

const Banner: React.FC<BannerProps> = ({onMissionClick}) => {
    const navigate = useNavigate()
    return (
        <div className="banner-container container-full">
            <div className="banner2">
                <div className="banner-image-testimonial">
                    <div className="banner-image">
                        <img src={BannerPhoto} alt="banner-logo" />
                    </div>
                    <div className="banner-testimonial">
                        <p>"I got into</p>
                        <p>Wharton, thanks</p>
                        <p>to Smart With a</p>
                        <p>Heart!"</p>
                        <span>- LJ HARRIS</span>
                    </div>
                </div>
                <div className="banner-text">
                    <h1 className="textMain">
                        <span className="text1">GRE Prep on Your</span>
                        <span className="text2">
                            Schedule.{" "}
                            <span className="colored">
                                {" "}
                                Plus,{" "}
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        fontFamily: "inherit",
                                    }}
                                >
                                    Free Help
                                </span>{" "}
                                When{" "}
                            </span>
                        </span>
                        <span className="text2">
                            <span className="colored">You Need It.</span>
                        </span>
                    </h1>
                    <div className="btn-Section">
                        <Button
                            className="btn btnSignUp"
                            onClick={() => navigate("/register/enroll")}
                        >
                            SIGN UP NOW
                        </Button>
                        <Button
                            className="btn btnTrial"
                            onClick={onMissionClick}
                        >
                            OUR MISSION
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
