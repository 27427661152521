import React, { useState } from "react"
import "./Home.scss"
import "./BlockContent.scss"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"

const types = {
    connect: "CONNECT",
    prepare: "PREPARE",
    advice: "ADVICE",
}
const data = {
    [types.connect]: {
        heading: "Join our community of aspiring grad students",
        subHeading:
            "Meet fellow graduate school applicants, get personalized advice at our free workshops, or volunteer!",
        description: (
            <p>
                Applying for graduate school is an arduous process and it helps
                to have the support of your peers. Smart with a Heart offers
                {" "}<a href="/connect">
                    free admissions consulting workshops
                </a>{" "}
                and{" "}
                <a href="/connect">
                    free resume workshops
                </a>{" "}
                every month. Meet fellow grad school aspirants, share your tips
                and resources, and get advice from Smart with a Heart's experts
                in our{" "}
                <a href="/discussion-forum">
                    discussion forum.
                </a>{" "}
                You can also explore our{" "}
                <a href="/connect?section=volunteer">
                    volunteer
                </a>{" "}
                and
                {" "}
                <a href="/connect?section=partnership-opportunities">
                    partnership opportunities!
                </a>
            </p>
        ),
        buttonText: "Join an event or discussion",
        url: "/connect",
    },
    [types.prepare]: {
        heading: "Take charge of your standardized test prep",
        subHeading:
            "Our comprehensive GRE curriculum includes one-on-one advisory meetings and small group office hours.",
        description: (
            <p>
                Our 9-step GRE program offers you a series of{" "}
                <a href="/gre">
                    90-minute pre-recorded classes,
                </a>{" "}
                which you can watch on your schedule, and{" "}
                <a href="/gre">
                    detailed homework assignments
                </a>{" "}
                from our{" "}
                <a href="/gre">
                    7-volume guide to the GRE.
                </a>{" "}
                Your program includes{" "}
                <a href="/how-our-course-works">
                    four 1:1 mentoring meetings
                </a>{" "}
                with a 99th percentile instructor, and if you need extra help,
                you have access to our{" "}
                <a href="/how-our-course-works">
                    live, small group office hours
                </a>{" "}
                (at no additional cost) any day of the week. Our all-inclusive
                program fee is $249 for six months of support.
            </p>
        ),
        buttonText: "Learn about our GRE course",
        url: "/gre",
    },
    [types.advice]: {
        heading: "Plan your application process",
        subHeading:
            "Learn the timeline and process for crafting an authentic and successful application.",
        description: (
            <p>
                Applying to graduate school is a big decision - here's{" "}
                <a href="/foresee-items">
                    what you should know
                </a>{" "}
                before you take the leap. We offer an{" "}
                <a href="/timeline">
                    application timeline
                </a>{" "}
                and some useful resources, and explain the purpose of
                standardized tests and{" "}
                <a href="/gre-need">
                    whether you should plan to take the GRE.
                </a>{" "}
                If you're pursuing an MBA, we offer some practical advice on{" "}
                <a href="/gre-or-gmat">
                    choosing between the GRE and the GMAT.
                </a>{" "}
                Reading these pages is a great way to prepare for our{" "}
                <a href="/connect">
                    free workshops,
                </a>{" "}
                and will help you make the most of the event!
            </p>
        ),
        buttonText: "Access our admissions advice",
        url: "/foresee-items",
    },
}

const BlockContent = () => {
    const [selectedType, setSelectedType] = useState(types.prepare)
    const navigate = useNavigate()

    const handleButtonNavigation = () => {
        if (selectedType === "CONNECT") {
            navigate("/connect")
        } else if (selectedType === "PREPARE") {
            navigate("/gre")
        } else if (selectedType === "ADVICE") {
            navigate("/foresee-items")
        }
    }

    return (
        <div className="block-content">
            <div className="buttons-block">
                <Button
                    className="link-btn btn-dark-blue font-900"
                    onClick={() => setSelectedType(types.connect)}
                >
                    Connect
                </Button>
                <Button
                    className="link-btn btn-green font-900"
                    onClick={() => setSelectedType(types.prepare)}
                >
                    GRE PREP
                </Button>
                <Button
                    className="link-btn btn-brown font-900"
                    onClick={() => setSelectedType(types.advice)}
                >
                    Advice
                </Button>
            </div>
            <div className="mba-section-content full-width">
                <div className="text-width">
                    <h2 className="title-page font-900 mt-30">
                        {data[selectedType].heading}
                    </h2>
                    <h3 className="sub-heading font-800">
                        {data[selectedType].subHeading}
                    </h3>
                    <div className="p-gray-lato">
                        {data[selectedType].description}
                    </div>
                    <div className="text-center pt-20">
                        <Button
                            className={`link-btn font-900 ${
                                selectedType === "CONNECT"
                                    ? "btn-dark-blue"
                                    : ""
                            } ${
                                selectedType === "PREPARE" ? "btn-green" : ""
                            } ${selectedType === "ADVICE" ? "btn-brown" : ""}`}
                            onClick={() => handleButtonNavigation()}
                        >
                            {data[selectedType].buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockContent
