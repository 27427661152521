
import React from "react"
import "./HowWeDoIt.scss"
import GreProgram from "../grePrep/GreProgram"
import { Link } from "react-router-dom"


const HowWeDoIt = () => {
  return (
    <div className="how-we-do-it">
      <div className="text-width">
        <h1 className=" title-gray text-center pt-unset">
          Our 9-step GRE prep program
        </h1>
        <p className="p-gray-lato pb-76 p-t-text">
          <span className="p-strong-lato">
            Our unique 9-step program is designed and taught by two industry
            leaders, Jay and Nafeez, the founders of
          </span>{" "}
          <a
            href="https://sherpaprep.com"
            className="link"
            target="_blank"
            >
            Sherpa Prep
          </a>
          . Both have over 20 years of experience teaching the GRE,
            and their course has hundreds of 5-star ratings on{" "}
          <a
            className="link"
            target="_blank"
            href="https://www.google.com/search?q=sherpa+prep+google+maps&tbm=lcl&ei=V-oSYajQC4K4tQbU3ZqgAw&oq=sherpa+prep+google+maps&gs_l=psy-ab.3..33i160k1j33i299k1.18603.22009.0.22154.13.13.0.0.0.0.218.1073.12j0j1.13.0....0...1c.1.64.psy-ab..0.13.1072...38j0i67k1j0i512k1j0i22i30k1j33i10i160k1j33i10k1.0.ueBTsVvcBFk#rlfi=hd:;si:2488420015734256675,l,ChdzaGVycGEgcHJlcCBnb29nbGUgbWFwcyIDiAEBSML5lavHkICACFodEAAQARgAGAEiC3NoZXJwYSBwcmVwKgYIAhAAEAGSARZlZHVjYXRpb25hbF9jb25zdWx0YW50;mv:[[38.9882953,-77.0412482],[38.8799709,-77.09911819999999]]"
            >
            Google
          </a>
          ,{" "}
          <a
            className="link"
            target="_blank"
            href="https://www.yelp.com/biz/sherpa-prep-washington-6?hrid=xOfA7fueK0x1IJ8bTiWvOw"
            >
            Yelp!
          </a>{" "}
            and{" "}
          <a
            className="link"
            target="_blank"
            href="https://www.trustpilot.com/review/sherpaprep.com"
            >
            Trustpilot.
          </a>{" "}
          Each of the nine program steps includes two 90-minute classes, which you can watch
          on your schedule, as well as detailed HW assignments from our proprietary 7-volume
          guide to the GRE. In addition, you can meet 1-1 with a 99th percentile instructor 
          up to 4 times, should you want personalized guidance and feedback – and, if you
          have questions from your homework or practice exams, you can join our live, small
          group office hours, at no additional cost, any day of the week. Smart with a Heart
          has also partnered with Sherpa Prep to provide you more than a dozen videos that
          provide a detailed understanding of the admissions process and everything associated with it.
        </p>
      </div>
      <GreProgram />
      <div className="text-width">
        <p className="p-gray-lato p-t-text">
          Learn more about how our program works{" "}
          <Link
            to="/how-our-course-works"
            >
            here.
          </Link>
        </p>
      </div>
    </div>
  )
}

export default HowWeDoIt
