import React from "react"
import "./GreProgram.scss"
import GreCourseIcon from "../../../../src/assets/images/gre-course-icon.svg"
import GreProblemIcon from "../../../../src/assets/images/gre-problem-icon.svg"
import GreTextbookIcon from "../../../../src/assets/images/gre-textbook-icon.svg"
import GreOfficeHoursIcon from "../../../../src/assets/images/gre-office-hour-icon.svg"
import GreAdvisorIcon from "../../../../src/assets/images/gre-advisor-icon.svg"
import GreTutorialIcon from "../../../../src/assets/images/gre-tutorial-icon.svg"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"

const GreProgram = () => {
    const navigate = useNavigate()
    return (
        <div className="container-full mb-80">
            <div className="gre-program">
                <div className="container">
                    <h2 className="title-page mb-18">
                        Our <span className="title-page text-red">$249</span>{" "}
                        GRE Program includes:
                    </h2>
                    <div className=" gre-program-list">
                        <div className="text-block">
                            <div className="icon">
                                <img src={GreCourseIcon} alt="course icon" />
                            </div>
                            <p className="p-gray-lato">
                                Our{" "}
                                <span className="text-black">
                                    9-step GRE course
                                </span>{" "}
                                consists of 17 classes, each 90 minutes long,
                                plus dozens of extra tutorials.
                            </p>
                        </div>
                        <div className="text-block">
                            <div className="icon">
                                <img src={GreProblemIcon} alt="problem icon" />
                            </div>
                            <p className="p-gray-lato">
                                <span className="text-black">
                                    1,000+ practice problems exams,{" "}
                                </span>
                                each with detailed how-to video explanations.
                            </p>
                        </div>
                        <div className="text-block">
                            <div className="icon">
                                <img
                                    src={GreTextbookIcon}
                                    alt="textbook icon"
                                />
                            </div>
                            <p className="p-gray-lato">
                                <span className="text-black">
                                    7 electronic textbooks
                                </span>{" "}
                                explaining every single math and verbal concept
                                tested by the GRE.
                            </p>
                        </div>
                        <div className="text-block">
                            <div className="icon">
                                <img
                                    src={GreOfficeHoursIcon}
                                    alt="office hour icon"
                                />
                            </div>
                            <p className="p-gray-lato">
                                Unlimited access to our live,{" "}
                                <span className="text-black">
                                    small-group office hours
                                </span>{" "}
                                - offered daily, save for holidays and holiday
                                weekends.
                            </p>
                        </div>
                        <div className="text-block">
                            <div className="icon">
                                <img src={GreAdvisorIcon} alt="advisor icon" />
                            </div>
                            <p className="p-gray-lato">
                                Up to 4{" "}
                                <span className="text-black">
                                    one-on-one advisory meetings
                                </span>{" "}
                                with a 99th percentile instructor for
                                personalized guidance.
                            </p>
                        </div>
                        <div className="text-block">
                            <div className="icon">
                                <img
                                    src={GreTutorialIcon}
                                    alt="tutorial icon"
                                />
                            </div>
                            <p className="p-gray-lato">
                                15 tutorials offering{" "}
                                <span className="text-black">
                                    MBA Admissions advice.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="gre-program-btn">
                        <Button
                            className="link-btn btn-blue font-900"
                            onClick={() => navigate("/register/enroll")}
                        >
                            Sign up Now
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GreProgram
