import React, { useRef, useEffect } from "react"
import AddToMailingList from "./AddToMailingList"
import Banner from "./Banner"
import BannerPhoto from "../../../assets/images/Home-Girl-1.png"
import "./Home.scss"
import Leadership from "./Leadership"
import CostOfApplying from "./CostOfApplying"
import DegreesProvide from "./DegreesProvide"
import BlockContent from "./BlockContent"
import SupportStudents from "./SupportStudents"
import Testimonials from "./Testimonials"
import CollapseInfo from "./CollapseInfo"
import { Seo } from "components/HOC/SEO"

const Home = () => {
    const missionRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const scrollToMission = () => {
        if (missionRef.current) {
            missionRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    return (
        <>
            <Seo
                description="Smart with a Heart is the first organization in America to provide professional graduate school test prep and admission consulting services on a nonprofit basis."
                keywords="smart with a heart, non-profit, admissions, gre, gmat, mba, universities"
                title="Welcome - Smart with a Heart"
            />

            <div className="Home">
                {/* <div className="banner-container container-full">
                    <div className="banner">
                        <div className="banner-image">
                            <img src={BannerPhoto} alt="banner-logo" />
                        </div>
                    </div>
                </div> */}
                <Banner onMissionClick={scrollToMission} />
                <div className="text-width">
                    <h1>
                        <div className="title-page font-900">
                            Welcome to{" "}
                            <span className="blue-text">
                                Smart with a Heart:
                            </span>{" "}
                        </div>
                        <div className="title-page font-900 mb-24">
                            America's First{" "}
                            <span className="green-text">Not-for-Profit</span>{" "}
                            Test Prep and Admissions Advice
                        </div>
                    </h1>
                    {/* <p className="p-gray-lato mb-0">
                        Smart with a Heart is the first organization in America
                        to provide professional graduate school test prep and
                        admission consulting services on a nonprofit basis.{" "}
                        <span className="p-strong-lato">
                            We believe deeply in the dream of educational equity
                            and expanding access to higher education.
                        </span>{" "}
                        We offer affordable GRE test prep, free admissions
                        advice, and connection to a community of fellow
                        applicants.
                    </p> */}
                </div>
                <CollapseInfo />
                <BlockContent />
                {/* <Leadership /> */}
                {/* <CostOfApplying /> */}
                {/* <AddToMailingList /> */}
                {/* <DegreesProvide /> */}
                {/* <SupportStudents /> */}
                {/* <Testimonials /> */}
            </div>
        </>
    )
}

export default Home
