
import React from "react"
import "./WhyWeDoIt.scss"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"


const WhyWeDoIt = () => {
  const navigate = useNavigate()

  return (
    <div className="why-we-do-it">
      <div className="text-width">
        <h1 className=" title-gray text-center pt-unset">
          The need for affordable test prep and admissions advice
        </h1>
        <p className="p-gray-lato pb-20 p-t-text">
          We are driven by a mission to {" "}
          <span className="p-strong-lato">
            make high-quality education accessible to all, regardless of socioeconomic background.
          </span>
          {" "}
          Education is a powerful tool for social
          mobility, yet too many students are left behind due to financial barriers.
          You can read more about {" "}
          <a
            href="https://sherpaprep.com"
            className="link"
            target="_blank"
          >
            our mission
          </a>
          .{" "}
        </p>
      </div>
      <div className="mba-section-content full-width">
        <p className="p-gray-lato text-width">
          Each year, over 350,000 students take the GRE, an exam critical for admission to
          graduate and business schools. The exam gives students who can afford expensive
          test prep programs a huge advantage: research shows

          {" "}
          <span className="p-strong-lato">
            that students who invest in GRE prep courses score, on average, 12-14 points higher than those who self-study.
          </span>
          {" "}
          Those additional points can be the difference between securing a spot at a top-tier
          institution and being overlooked by admissions committees. But these courses often
          cost thousands of dollars, especially if you seek private instruction.
          {" "}
          <span className="p-strong-lato">
            Many promising students lack the resources to access the preparation needed to
            achieve their best possible score.
          </span>
          {" "}
        </p>
      </div>

      <div className="text-width">
        <p className="p-gray-lato pb-20 p-t-text">
          We’re closing this gap by offering affordable, high-quality test prep
          that includes one-on-one guidance at no extra cost to the student. We
          know that standardized tests like the GRE are not just exams—they are
          gateways to better opportunities, higher education, and fulfilling careers.
          Our goal is to ensure that every student, regardless of their financial situation,
          gets a fair shot at success.
          {" "}
          <span className="p-strong-lato">
            Our nonprofit model allows us to offer GRE prep for as little as $199
          </span>
          {" "}
          (for veterans, Pell Grant recipients, and those referred by our partner nonprofits)
          {" "}
          <span className="p-strong-lato">
            and $249 for everyone else
          </span>
          {" "}
          while providing personalized guidance and expertise based on 20 years of teaching the exam.
        </p>

        <div className="text-center mt-20">
          <Button
            className="link-btn btn-green font-900"
            onClick={() => navigate("/gre")}
            >
            Learn About Our Course
          </Button>
					</div>
      </div>
    </div>
  )
}

export default WhyWeDoIt
