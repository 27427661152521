import React from "react";
import "./testimonialCard.scss";

interface TestimonialCardProps {
  image: string;
  name: string;
  veteranStatus: string;
  score: string;
  schools: string;
  text: string;
  stars: number;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ image, name, veteranStatus, score, schools, text, stars }) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-header">
        <img src={image} alt={name} className="testimonial-image" />
        <div className="testimonial-info">
          <h3 className="testimonial-name p-strong-lato-blue">{name}</h3>
          <p className="testimonial-status p-lato-regular">{veteranStatus}</p>
          <div className="score-school">
            <h4 className="testimonial-score p-strong-lato-blue">{score}</h4>
            <p className="testimonial-schools p-lato-regular">{schools}</p>
          </div>
        </div>
      </div>

      <div className="testimonial-body">
        <div className="testimonial-stars">
          {"★".repeat(stars)}
        </div>
        <p className="testimonial-text p-gray-lato">{text}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
