
import React from "react"
import "./WhatWeDo.scss"
import WhoWeAre from "../../../assets/images/who-we-are.svg"
import Expertise from "../../../assets/images/expertise.svg"
import WhatMakeDifferent from "../../../assets/images/what-make-different.svg"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"


const WhatWeDo = () => {
    const navigate = useNavigate()

	return (
		<div className="what-we-do">
				<div className="text-width">
					<p className="p-gray-lato pb-20 p-t-text">
							Smart with a Heart is the first organization in America to provide
							professional graduate school test prep services on a nonprofit basis.
							{" "}
							<span className="p-strong-lato">
							We believe deeply in the dream of educational equity and expanding
									access to higher education. We offer a comprehensive GRE prep course for just $249,
							</span>{" "}
							and our program includes one-on-one teacher guidance. Our course is produced and
							taught by Sherpa Prep, one of the most highly-rated test prep companies in the world.
							Unlike any other test prep provider, we also include hours of admissions tutorials in
							our program free of charge. These tutorials offer sensible, money-saving advice that are
							designed to save you hundreds or thousands of dollars in admissions consulting fees – and
							will make you an educated consumer of any services that you do decide to pay for.
							We also offer connection to a community of fellow applicants for advice and moral support!
					</p>

					<div className="text-center">
							<Button
									className="link-btn btn-brown font-900"
									onClick={() => navigate("/gre")}
							>
									Learn More
							</Button>
					</div>
				</div>

				<div className="support-students">
					<div className="text-width">
							<h2 className="title-page font-900 mt-20 blue-h2 mb-30">
									Smart with a Heart leverages Sherpa Prep’s 20 years
									of teaching expertise to deliver affordable support to
									students.
							</h2>
							<hr className="mt-30" />
							<div className="text-block dashed-border">
									<div>
											<img src={WhoWeAre} alt="Who We Are" />
									</div>
									<div className="p-strong-lato">Who we are</div>
									<p className="p-gray-lato mb-0">
											Smart with a Heart is the first organization in America
											to provide{" "}
											<span className="p-strong-lato">
													professional GRE preparation and admission
													consulting services on a nonprofit basis.
											</span>{" "}
											We believe deeply in the dream of educational equity and
											the life-changing pathways to prosperity that higher
											education provides.
									</p>
							</div>
							<div className="text-block dashed-border">
									<div>
											<img src={Expertise} alt="Expertise" />
									</div>
									<div className="p-strong-lato">Our Expertise</div>
									<p className="p-gray-lato mb-0">
											<span className="p-strong-lato">Sherpa Prep’s</span>{" "}
											success over the past 20 years has positioned it as
											perhaps the single{" "}
											<span className="p-strong-lato">
													highest rated rated test prep company.
											</span>{" "}
											Their curriculum and network of expert instructors will
											provide our students with a high quality learning
											experience that has never before been available at this price point.
									</p>
							</div>
							<div className="text-block">
									<div>
											<img src={WhatMakeDifferent} alt="WhatMakeDifferent" />
									</div>
									<div className="p-strong-lato">What Makes Us Different</div>
									<p className="p-gray-lato mb-0">
											Our offering of{" "}
											<span className="p-strong-lato">
													asynchronous online learning modules coupled with
													1-on-1 live instruction for $249
											</span>{" "}
											is unique to the industry. Further, our longstanding
											mission-driven emphasis of expanding access to test prep
											for veterans and under-resourced candidates is a notable
											differentiator in today’s test prep environment.​
									</p>
							</div>
					</div>
				</div>
		</div>
	)
}

export default WhatWeDo
