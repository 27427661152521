
import React from "react"
import "./OurStudents.scss"
import S1 from "../../../assets/images/annual-report/bryce-randall.png";
import S2 from "../../../assets/images/annual-report/kelvin-white.png";
import S3 from "../../../assets/images/annual-report/christian-ochoa.png";
import S4 from "../../../assets/images/annual-report/lamar-harris.png";
import TestimonialCard from "components/smart/annualReport/testimonialCard";

const testimonials = [
  {
    image: S1,
    name: "Bryce Randall",
    veteranStatus: "Veteran",
    score: "319 CRE",
    schools: "Chicago Booth, Michigan Ross, UT McCombs",
    text: "I'd tried other prep services before SwaHt, but I saw a lot of vets succeed with this program - switching to SwaHt was a great decision.",
    stars: 5,
  },
  {
    image: S4,
    name: "Lamar Johnson-Harris",
    veteranStatus: "Veteran",
    score: "324 CRE",
    schools: "Wharton, Chicago Booth, Columbia, Michigan Ross, Dartmouth Tuck",
    text: "Course videos were super thorough, and taught at a very high level, so much that I barely had to use office hours.",
    stars: 5,
  },
  {
    image: S2,
    name: "Kelvin White",
    veteranStatus: "Veteran",
    score: "315 CRE",
    schools: "Notre Dame Mendoza, CMU Tepper",
    text: "Videos and readings helped me a lot, I ended up with a 15 point increase in my overall score. Would definitely recommend!",
    stars: 5,
  },
  {
    image: S3,
    name: "Christian Ochoa",
    veteranStatus: "Veteran",
    score: "322 CRE",
    schools: "Chicago Booth, Dartmouth Tuck, Michigan Ross",
    text: "I thought the practice questions were harder than the actual exam, which is what you want from a prep service.",
    stars: 5,
  },
];

const OurStudents = () => {
  return (
    <div className="our-students">
      <div className="text-width mb-24">
        <h1 className="title-gray text-center pt-unset">
          Hear from our class of 2023
        </h1>
        <p className="p-gray-lato">
          We’ve had the privilege of working with over 200 incredible students
          who have gone on to achieve remarkable results. From our 80-strong class of 2023,
          37 are attending top-20 MBA programs across the world (47%) ,
          and have received $1M+ in scholarship money. 
        </p>
        <p className="p-gray-lato">
          Here are just a few highlights
        </p>

        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              image={testimonial.image}
              name={testimonial.name}
              veteranStatus={testimonial.veteranStatus}
              score={testimonial.score}
              schools={testimonial.schools}
              text={testimonial.text}
              stars={testimonial.stars}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurStudents
