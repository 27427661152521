import { Collapse } from 'antd';
import "./CollapseInfo.scss"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"
import WhatWeDo from './WhatWedo';
import WhyWeDoIt from './WhyWeDoIt';
import HowWeDoIt from './HowWeDoIt';
import WhatMakesDifferent from './WhatMakesDifferent';
import OurStudents from './OurStudents';
const { Panel } = Collapse;
const iconStyle = { fontSize: "1rem" }

const CollapsableContent = [
  {
    id: 1,
    heading: "What we do",
    component: WhatWeDo,
  },
  {
    id: 2,
    heading: "Why we do it",
    component: WhyWeDoIt,
  },
  {
    id: 3,
    heading: "How we do it",
    component: HowWeDoIt,
  },
  {
    id: 4,
    heading: "What makes us different",
    component: WhatMakesDifferent,
  },
  {
    id: 5,
    heading: "What our students say",
    component: OurStudents,
  },
];
const CollapseInfo = () => {
  return (
    <div className="Collapse-info">
      <div className="content-page">
        <div className="MainFaqItem">
            <Collapse
                bordered={false}
                expandIcon={({ isActive }) =>
                    isActive ? (
                        <MinusOutlined style={iconStyle} />
                    ) : (
                        <PlusOutlined style={iconStyle} />
                    )
                }
            >
                {CollapsableContent.map((item) => {
                  const Component = item.component;
                  return (
                    <Panel header={item.heading} key={item.id}>
                      <div className="mt-20">
                        <Component />
                      </div>
                    </Panel>
                  );
                })}
            </Collapse>
        </div>
      </div>
    </div>
  )
}

export default CollapseInfo
