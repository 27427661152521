
import React from "react"
import "./WhatMakesDifferent.scss"
import Metric from "../../../assets/images/key-metric.png"


const bulletContent = [
  {
    heading: "We believe in teacher-led learning, not flashy tech.",
    text: `Our course is taught by the founders of Sherpa Prep, Jay and Nafeez, who have over
          20 years of daily experience teaching the GRE. None of our questions or materials are
          AI-generated: they are handcrafted by experts who fundamentally understand the exam
          and understand how students learn.`
  },
  {
    heading: "We believe in hands-on mentorship.",
    text: `Through our one-on-one advisory meetings (or “check-ins”), each student has access
          to personalized attention from a 99th percentile instructor, and receives detailed
          feedback on their progress, helping them identify specific areas for improvement.`
  },
  {
    heading: "We believe education is for everyone.",
    text: `We do not “price out” potential students, and offer our services at a competitive
          price that everyone can afford. 61% of our students are veterans, and 51% identify
          as underrepresented minorities.`
  },
  {
    heading: "We want our students to have the most comprehensive test prep material available to them.",
    text: `This includes 17 classes, each 90 minutes long (plus dozens of extra tutorials), 1,000+ practice
          problems (each with detailed how-to video explanations), 7 electronic textbooks explaining every
          single math and verbal concept tested by the GRE.`
  },
  {
    heading: "We want to guide our students in the MBA admissions journey.",
    text: `We offer 15 tutorials with MBA admissions guidance, with over 3 hours of content
          at their disposal. Our students have used these resources very successfully to achieve
          admissions to top-20 business schools, and we’re the only test prep provider
          in the world that includes admissions guidance as part of our course.`
  }
];


const WhatMakesDifferent = () => {
  return (
    <div className="what-different">
      <div className="text-width mb-24">
        <h1 className=" title-gray text-center pt-unset">
          Personalized, teacher-led instruction
        </h1>
        <p className="p-gray-lato">
          Smart with a Heart stands out by offering personalized support and content that surpasses
          the quality of much higher-priced services.
        </p>
      </div>
      <div className="mba-section-content full-width">
        <p className="text-width">
          <ul>
            {bulletContent.map((item, index) => (
              <li key={index}>
                <span className="p-strong-lato">{item.heading}</span> {item.text}
              </li>
            ))}
          </ul>
        </p>
      </div>

      <div className="text-width">
        <h1 className="title-page font-900 mb-24 multi-h2">
          <span className="d-block">Impact Highlights from class of 2023</span>
        </h1>
        <div className="metric-image">
          <img src={Metric} alt="Growth metrics" />
        </div>
      </div>
    </div>
  )
}

export default WhatMakesDifferent
